<template>
  <div>
    <head-layout  :head-title="$t('cip.dc.shunt.title.name')"  >
    </head-layout>


        <div style="display: flex;;" class="search-form">
          <el-input :placeholder="$t('cip.dc.shunt.msg.diversionCode')" v-model="queryList.param1" class="new-sino-input" size="mini" style="width:200px" />

          <el-button-group style="margin-left: 10px;">
            <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
          </el-button-group>

        </div>


        <grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-add =  'addFn'
            @grid-edit="handleEdit"
          >
          <template slot="isEnable" slot-scope="{row}" >
            {{row.isEnable=='Y'?$t('shunt.table.startUsing'):$t('shunt.table.forbidden')}}
            </template>
          </grid-layout>







  </div>
</template>

<script>
import {
  dataSplitList,
  dataSplitDel,
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      tableLoading:false,
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "grid-add",
          remark: 'edit',
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },
      ],
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        column: [
          {
            label: this.$t('cip.dc.shunt.field.ShuntCoding'),
            prop: "infCodeSplit",
            cell: true,
          },
          {
            label: this.$t('cip.dc.shunt.field.InterfaceCode'),
            prop: "infCode",
            cell: true,
          },
          {
            label: this.$t('cip.dc.shunt.field.Transcoding'),
            prop: "splitCode",
            cell: true,
          },
          {
            label: this.$t("cip.dc.shunt.field.describe"),
            prop: "infDesc",
            cell: true,
          },


          {
            label: this.$t('cip.dc.shunt.field.conditionsForExecution'),
            prop: "conditions",
            cell: true,
          },
          {
            label: this.$t('cip.dc.shunt.field.isEnable'),
            prop: "isEnable",
            slot:true,
            cell: true,

          },
        ],
      },
      multipleSelection:[],
      tableData: [],
      selDat:[],
      total:0,
      queryList:{
        query:{
          current:1,
          size:20,
        },
        param1: "",
      },
      form:{
        conditions: "",//执行条件
        infCode: "",//接口编码
        infCodeSplit: "",//转换编码
        infDesc: "",//描述
        isEnable: "",//是偶发启用
      },
      dialogFormVisible:false,
      loading:false,

      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, ],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      dataSplitList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.dataList
        this.total = obj.page.totalSize
        this.page.total=obj.page.totalSize;
      });
    },
    addFn(){
      this.$router.push({
        path: '/dataHandle/shuntAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(item){
      this.$router.push({
        path: '/dataHandle/shuntAdd',
        query: {
          type: 'edit',
          id:item.id
        },
      })
      localStorage.setItem('shuntEditObj',JSON.stringify(item))
    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return Number(item.id)
      })
      this.$confirm(this.$('cip.dc.shun.msg.isDelect'), this.$('cip.dc.shun.btn.tip'), {
        confirmButtonText: this.$('cip.dc.shun.btn.confirmButtonText'),
        cancelButtonText: this.$('cip.dc.shun.btn.cancelButtonText'),
        type: 'warning'
      }).then(() => {
        dataSplitDel({ids}).then((res)=>{
          this.$message.success(this.$('cip.dc.shun.msg.DeleteSucceeded'));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList = {
        query:{
          current:1,
          size:10,
        },
        param1: "",
      }
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)

      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.queryList.query.size  = val.pageSize;
      this.page.pageSize= val.pageSize
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.query.current = val.currentPage;
      this.onLoad()
    },
  },
  created() {
    this.onLoad()
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
</style>
